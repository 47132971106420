var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-navbar-nav',{attrs:{"align":"center"}},[_c('b-nav-text',[_c('b-icon-three-dots-vertical')],1),_c('b-nav-item',{attrs:{"to":{
      name: 'admx-set-view',
      params: Object.assign({}, _vm.baseLinkParams,
        {policyClass: 'Machine'}),
    }}},[_c('admx-icon-policy-machine')],1),_c('b-nav-item',{attrs:{"to":{
      name: 'admx-set-view',
      params: Object.assign({}, _vm.baseLinkParams,
        {policyClass: 'User'}),
    }}},[_c('admx-icon-policy-user')],1),_c('b-nav-item',{attrs:{"to":{
      name: 'admx-set-registry',
      params: Object.assign({}, _vm.baseLinkParams,
        {policyClass: 'Machine'}),
    }}},[_c('admx-icon-registry-machine')],1),_c('b-nav-item',{attrs:{"to":{
      name: 'admx-set-registry',
      params: Object.assign({}, _vm.baseLinkParams,
        {policyClass: 'User'}),
    }}},[_c('admx-icon-registry-user')],1),_c('b-nav-item',{attrs:{"to":{
      name: 'admx-set-files',
      params: {
        setId: _vm.setId,
      },
    }}},[_c('admx-icon-files')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }