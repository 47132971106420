<template>
  <b-navbar-nav align="center">
    <b-nav-text>
      <b-icon-three-dots-vertical />
    </b-nav-text>
    <b-nav-item
      :to="{
        name: 'admx-set-view',
        params: {
          ...baseLinkParams,
          policyClass: 'Machine',
        },
      }"
    >
      <admx-icon-policy-machine />
    </b-nav-item>
    <b-nav-item
      :to="{
        name: 'admx-set-view',
        params: {
          ...baseLinkParams,
          policyClass: 'User',
        },
      }"
    >
      <admx-icon-policy-user />
    </b-nav-item>
    <b-nav-item
      :to="{
        name: 'admx-set-registry',
        params: {
          ...baseLinkParams,
          policyClass: 'Machine',
        },
      }"
    >
      <admx-icon-registry-machine />
    </b-nav-item>
    <b-nav-item
      :to="{
        name: 'admx-set-registry',
        params: {
          ...baseLinkParams,
          policyClass: 'User',
        },
      }"
    >
      <admx-icon-registry-user />
    </b-nav-item>
    <b-nav-item
      :to="{
        name: 'admx-set-files',
        params: {
          setId,
        },
      }"
    >
      <admx-icon-files />
    </b-nav-item>
  </b-navbar-nav>
</template>

<script>
export default {
  computed: {
    baseLinkParams() {
      return {
        setId: this.setId,
        languageCode: this.languageCode || "en-US",
      };
    },
    setId() {
      return this.$route.params.setId;
    },
    languageCode() {
      return this.$route.params.languageCode;
    },
  },
};
</script>
